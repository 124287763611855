import { mapGetters, mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations(["setAppointmentInfo"]),
  },
  computed: {
    ...mapGetters(["getUserType", "getInfoAppointmentPayment"]),
    appointmentInfo: {
      get() {
        return this.getInfoAppointmentPayment;
      },
      set(value) {
        this.setAppointmentInfo(value);
      },
    },
    canUploadPaymentEvidence() {
      const canRejectSecondOpinion = ["manager_cacit", "superadmin", "cacit"];
      return canRejectSecondOpinion.includes(this.getUserType);
    },
    canRejectSecondOpinion() {
      const canRejectSecondOpinion = ["manager_cacit", "superadmin"];
      return canRejectSecondOpinion.includes(this.getUserType);
    },
    canConfirmSecondOpinion() {
      const canConfirmSecondOpinion = ["manager_cacit", "superadmin"];
      return canConfirmSecondOpinion.includes(this.getUserType);
    },
    canRemovePaymentEvidence() {
      const users = ["superadmin", "manager_cacit"];
      return users.includes(this.getUserType);
    },
    isPaid() {
      return this.appointmentInfo?.is_paid || false;
    },
    isSecondOpinion() {
      return this.isPaid === "free_of_charge" || this.isSecondOpinionConfirmed;
    },
    isAppointmentPaidUploaded() {
      return this.isPaid === "paid_out" && this.uploadDataValidation;
    },
    isAppointmentNotPayed() {
      return this.isPaid === "not_payed" && !this.uploadDataValidation;
    },
    isSecondOpinionWithFiles() {
      if (
        this.isSecondOpinion &&
        this.appointmentInfo.second_opinion_doc.length > 0
      ) {
        return true;
      }
      return false;
    },
    uploadDataValidation() {
      if (this.appointmentInfo?.vouchers && !this.isSecondOpinion) {
        return this.appointmentInfo.vouchers.length !== 0;
      }
      return false;
    },
    isSecondOpinionConfirmed() {
      return this.appointmentInfo?.second_opinion_paid;
    },
    filesToShow() {
      if (this.isSecondOpinion) {
        return this.appointmentInfo?.second_opinion_doc;
      }
      return this.appointmentInfo?.vouchers;
    },
  },
};
