<template>
  <div class="container p-4">
    <div class="row">
      <div class="titles mb-5">
        {{ titleByPaymentType }}
      </div>
    </div>
    <UploadContent
      ref="uploadContent"
      v-if="!uploadDataValidation && !isSecondOpinion" />
    <PreviewContent
      v-if="uploadDataValidation || isSecondOpinion"
      @closeModal="closeModal" />
    <FooterButtons
      v-if="hasFooterButtons"
      @confirmReject="confirmReject"
      @confirmSecondOpinion="confirmSecondOpinion"
      @confirmRemove="confirmRemove"
      @saveFiles="saveFiles"
      @closeModal="closeModal" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import paymentModal from "@/mixin/paymentModal.mixin";

export default {
  name: "PaymentEvidence",
  props: {
    hasFooterButtons: {
      type: Boolean,
      default: true,
    },
    haSecondOpinion: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadContent: () =>
      import("@/components/patient/filiation/modals/UploadContent.vue"),
    PreviewContent: () =>
      import("@/components/patient/filiation/modals/PreviewContent.vue"),
    FooterButtons: () =>
      import("@/components/patient/filiation/modals/FooterButtons.vue"),
  },
  mixins: [paymentModal],
  computed: {
    ...mapGetters(["getPatientSelected"]),
    btnActionTitle() {
      if (
        !this.uploadDataValidation &&
        !(this.isPaid === "free_of_charge" || this.isPaid === "paid_out")
      )
        return "Cargar";
      return "Confirmar";
    },
    titleByPaymentType() {
      if (this.haSecondOpinion) return "Segunda Opinión Confirmada";
      if (this.isSecondOpinionConfirmed) return "Segunda Opinión Confirmada";
      if (!this.uploadDataValidation && this.isPaid === "not_payed")
        return "Cargar Evidencia de Pago";
      if (this.uploadDataValidation || this.isPaid === "paid_out")
        return "Evidencia de Pago";
      return "¿Desea confirmar que es una segunda opinión?";
    },
  },
  methods: {
    ...mapActions(["updateConfirmData"]),
    confirmReject() {
      this.updateConfirmData({
        title: "Rechazar segunda opinión",
        description:
          "¿Esta seguro de querer rechazar esta segunda opinión? \n Esta acción cambia el estado del paciente a pagara en clinica y no se puede deshacer",
        okText: "Rechazar",
        cancelText: "Cancelar",
        confirmFunction: this.rejectSecondOpinion,
      });
    },
    confirmRemove(id) {
      this.updateConfirmData({
        title: "Remover comprobante de pago",
        description:
          "¿Esta seguro de querer borrar este comprobante de pago? \n Esta acción elimina todos los comprobantes de pago y no se puede deshacer",
        okText: "Eliminar",
        cancelText: "Cancelar",
        params: id,
        confirmFunction: this.removePaymentEvidence,
      });
    },
    rejectSecondOpinion() {
      this.$api.appointments
        .rejectSecondOpinion(this.getPatientSelected.next_appointment_id)
        .then(() => {
          this.closeModal();
          this.$emit("reloadPatientList");
        });
    },
    removePaymentEvidence() {
      this.$api.appointments
        .removePaymentEvidence(this.getPatientSelected.next_appointment_id)
        .then(() => {
          this.closeModal();
          this.$emit("reloadPatientList");
        });
    },
    closeModal() {
      // Appointment info is declared on mixin paymentModal
      this.appointmentInfo = {};
      this.$store.getters.getModal("generalFiliationModal").hide();
    },
    getAppointmentInfo() {
      const payload = this.hasFooterButtons
        ? this.getPatientSelected.next_appointment_id
        : this.getPatientSelected.appointment_id;
      this.$api.appointments.getInfo(payload).then((response) => {
        // Appointment info is declared on mixin paymentModal
        this.appointmentInfo = response.data;
        if (
          this.appointmentInfo.is_paid === "free_of_charge" &&
          this.appointmentInfo.second_opinion_doc.length === 0
        ) {
          this.$store.dispatch("updateAlerts", {
            content: "Requiere cargar comprobantes de segunda opinión",
            status: "error",
          });
          this.closeModal();
        }
      });
    },
    saveFiles() {
      const payload = new FormData();
      // eslint-disable-next-line camelcase
      const { files } = this.$refs.uploadContent.uploadData;
      files.forEach((voucher) => {
        payload.append("vouchers[]", voucher);
      });
      payload.append("is_paid", "paid_out");
      payload.append(
        "appointment_id",
        this.getPatientSelected.next_appointment_id
      );
      this.sendData(payload);
    },
    confirmSecondOpinion() {
      const payload = new FormData();
      payload.append("is_paid", "paid_out");
      payload.append("second_opinion_paid", true);
      payload.append(
        "appointment_id",
        this.getPatientSelected.next_appointment_id
      );
      this.sendData(payload);
    },
    sendData(payload) {
      this.$api.appointments.updateAppointmentPaid(payload).then(() => {
        this.$emit("reloadPatientList");
        this.$emit("resetComponent");
      });
    },
  },
};
</script>

<style></style>
